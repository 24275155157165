//
// layouts.scss
//

// scrollable-layout

@include media-breakpoint-up(xl) {
  .scrollable-layout {
    padding-bottom: 0;

    #wrapper {
      display: flex;
    }

    .left-side-menu {
      position: relative;
      min-width: 250px;
      min-height: 100vh;
    }

    .navbar-custom {
      position: absolute;
    }

    .content-page {
      margin-left: 0;
      width: 100%;
    }

    &.left-side-menu-condensed {
      .left-side-menu {
        min-width: 70px;
      }

      .content-page {
        margin-left: 0px !important;
      }
    }
  }

}

// boxed-layout

.boxed-layout {
  background: darken($body-bg, 2%);

  #wrapper {
    max-width: $boxed-layout-width;
    margin: 0 auto;
    box-shadow: $box-shadow;
    background: $body-bg;
  }

  .navbar-custom {
    max-width: $boxed-layout-width;
    margin: 0 auto;
  }

  .footer {
    margin: 0 auto;
    max-width: calc(#{$boxed-layout-width} - #{$leftbar-width});
    background: $body-bg;
  }

  &.left-side-menu-condensed {
    min-height: auto;

    .footer {
      max-width: calc(#{$boxed-layout-width} - #{$leftbar-width-collapsed});
    }
  }
}

// right sidebar content

.sidebar {
  &-body {
    grid-template-columns: 300px 300px;
    display: grid;
  }
}

.join-btn {
    font-size: 14px !important;
    color: $blue;
    width: 288px !important;
    height: 40px !important;
    border-radius: 4px;
    background-color: #ededfe;
    border: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    font-family: Manrope;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
    &:hover {
        background-color: #ebebff;
    }
    &.notification-additional-button {
        position: absolute;
        width: 190px !important;
        height: 45px !important;
        top: 67px;
        right: 0;
        z-index: 1000;
    }
}